
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import sortBy from 'lodash/sortBy';
import GButton from '@/components/gsk-components/GskButton.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { NumberField, RadioField, TextField, MultiSelectField } from '@/components/form/form.types';
import {
  CreateFeatureFlagBody,
  FeatureFlag,
  UpdateFeatureFlagBody,
} from '@/types/feature-flags.types';
import { openErrorSnackbar } from '@/utils/components';
import { TeamsModule } from '@/store/modules/teams.module';

type NewFlagForm = [
  TextField<'featureFlagName'>,
  TextField<'mnemonic'>,
  TextField<'featureFlagDescription'>,
  NumberField<'sequenceNumber'>,
  RadioField<'featureFlagEnabled'>,
  MultiSelectField<'featureEnabledTeams'>,
];

type EditFlagForm = [
  RadioField<'featureFlagEnabled'>,
  TextField<'featureFlagName'>,
  TextField<'mnemonic'>,
  TextField<'featureFlagDescription'>,
  NumberField<'sequenceNumber'>,
  MultiSelectField<'featureEnabledTeams'>,
];

@Component({
  components: {
    GButton,
    MaxWidth,
    DeleteDialog,
    ValidatedFormDialog,
    Promised,
  },
})
export default class ApprovalView extends Vue {
  newFlagOpen = false;
  newFlagForm: NewFlagForm | null = null;
  setNewFlag() {
    this.newFlagOpen = true;
    this.newFlagForm = [
      {
        key: 'featureFlagName',
        label: 'Feature Name',
        type: 'text',
        value: '',
        required: true,
        attrs: {
          maxLength: '50',
        },
        validation: {
          rules: 'required|max:50',
        },
      },
      {
        key: 'mnemonic',
        label: 'Feature Mnemonic',
        type: 'text',
        value: '',
        required: true,
        helpText: 'UPPERCASE, no spaces',
        attrs: {
          maxLength: '50',
        },
        validation: {
          rules: 'required|max:50|regex:^[A-Z]{1}[A-Z0-9]+$',
        },
      },
      {
        key: 'featureFlagDescription',
        label: 'Feature Description',
        type: 'long-text',
        value: '',
        required: true,
        attrs: {
          maxLength: '255',
        },
        validation: {
          rules: 'required|max:255',
        },
      },
      {
        key: 'sequenceNumber',
        label: 'Sequence Number',
        type: 'number',
        value: 1,
        required: true,
        attrs: {
          maxLength: '3',
        },
        validation: {
          rules: 'required|integer|min_value:1|max_value:999',
        },
      },
      {
        key: 'featureFlagEnabled',
        label: 'Feature Enabled',
        type: 'radio',
        value: '0',
        required: true,
        options: [
          {
            label: 'Enabled',
            value: '1',
          },
          {
            label: 'Disabled',
            value: '0',
          },
        ],
      },
      {
        key: 'featureEnabledTeams',
        label: 'Associate Teams',
        type: 'multi-select',
        attrs: {
          type: 'chips',
        },
        options: Object.entries(TeamsModule.teams).map(([key, team]) => {
          return {
            value: team.teamName,
            key: team.teamId.toString(),
          };
        }),
        value: [],
      },
    ];
  }

  get newFlagFormData(): CreateFeatureFlagBody {
    const out: CreateFeatureFlagBody = {
      featureFlagEnabled: false,
      featureFlagName: '',
      featureFlagDescription: '',
      sequenceNumber: -1,
      mnemonic: '',
      featureEnabledTeams: [],
    };
    this.newFlagForm?.forEach(f => {
      if (f.key === 'featureFlagEnabled') {
        out[f.key] = !!+f.value;
      } else if (f.key === 'sequenceNumber') {
        out[f.key] = f.value ?? 0;
      } else if (f.key === 'featureEnabledTeams') {
        out[f.key] = this.getTeamsForFeatureFlag(f.value);
      } else {
        out[f.key] = f.value;
      }
    });
    return out;
  }
  newFlagLoading = false;

  getTeamsForFeatureFlag(featureEnabledTeams: string[]) {
    let teams = TeamsModule.teams
      .filter(team => featureEnabledTeams.includes(team.teamName))
      .map(team => {
        return {
          teamId: team.teamId,
          teamName: team.teamName,
        };
      });
    return teams;
  }

  createFlag() {
    this.newFlagLoading = true;
    FeatureFlagsModule.createFeatureFlag(this.newFlagFormData)
      .then(() => {
        this.newFlagOpen = false;
        FeatureFlagsModule.getFeatureFlags();
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.toString());
      })
      .finally(() => (this.newFlagLoading = false));
  }

  editFlagOpen = false;
  editFlag: FeatureFlag | null = null;
  editFlagForm: EditFlagForm | null = null;
  editFlagLoading = false;
  setEditFlag(flag: FeatureFlag) {
    this.editFlag = flag;
    this.editFlagForm = [
      {
        key: 'featureFlagEnabled',
        label: 'Feature Enabled',
        type: 'radio',
        value: flag.featureFlagEnabled ? '1' : '0',
        required: true,
        options: [
          {
            label: 'Enabled',
            value: '1',
          },
          {
            label: 'Disabled',
            value: '0',
          },
        ],
      },
      {
        key: 'featureFlagName',
        label: 'Feature Name',
        type: 'text',
        value: flag.featureFlagName,
        required: true,
        attrs: {
          maxLength: '50',
        },
        validation: {
          rules: 'required|max:50',
        },
      },
      {
        key: 'mnemonic',
        label: 'Feature Mnemonic',
        type: 'text',
        value: flag.mnemonic,
        required: true,
        helpText: 'You probably should not be changing this',
        attrs: {
          maxLength: '50',
          disabled: '1',
          persistenthelpertext: '1',
        },
        validation: {
          rules: 'required|max:50|regex:^[A-Z]{1}[A-Z0-9]+$',
        },
      },
      {
        key: 'featureFlagDescription',
        label: 'Feature Description',
        type: 'long-text',
        value: flag.featureFlagDescription,
        required: true,
        attrs: {
          maxLength: '255',
        },
        validation: {
          rules: 'required|max:255',
        },
      },
      {
        key: 'sequenceNumber',
        label: 'Sequence Number',
        type: 'number',
        value: flag.sequenceNumber,
        required: true,
        attrs: {
          maxLength: '3',
        },
        validation: {
          rules: 'required|integer|min_value:1|max_value:999',
        },
      },
      {
        key: 'featureEnabledTeams',
        label: 'Associate Teams',
        type: 'multi-select',
        options: Object.entries(TeamsModule.teams).map(([key, team]) => {
          return {
            value: team.teamName,
            key: team.teamId.toString(),
          };
        }),
        value:
          flag.featureEnabledTeams?.map(team => {
            return team.teamName;
          }) || [],
      },
    ];
    this.editFlagOpen = true;
  }
  get editFlagFormData(): UpdateFeatureFlagBody | null {
    if (this.editFlag && this.editFlagForm) {
      const out: UpdateFeatureFlagBody = {
        featureFlagId: this.editFlag.featureFlagId,
        mnemonic: '',
        sequenceNumber: 1,
        featureFlagEnabled: false,
        featureFlagDescription: '',
        featureFlagName: '',
        featureEnabledTeams: [],
      };
      for (const field of this.editFlagForm) {
        if (field.key === 'featureFlagEnabled') {
          out[field.key] = !!+field.value;
        } else if (field.key === 'sequenceNumber') {
          out[field.key] = field.value ?? 0;
        } else if (field.key === 'featureEnabledTeams') {
          out[field.key] = this.getTeamsForFeatureFlag(field.value);
        } else {
          out[field.key] = field.value;
        }
      }

      return out;
    }
    return null;
  }
  updateFlag() {
    if (this.editFlagFormData) {
      this.editFlagLoading = true;
      FeatureFlagsModule.updateFeatureFlag(this.editFlagFormData)
        .then(() => {
          this.editFlagOpen = false;
          FeatureFlagsModule.getFeatureFlags();
        })
        .catch(e => {
          openErrorSnackbar.call(this, e.toString());
        })
        .finally(() => {
          this.editFlagLoading = false;
          this.editFlag = null;
        });
    }
  }

  get updateComplete() {
    return FeatureFlagsModule.updateComplete;
  }
  get featureFlags() {
    return sortBy(Object.values(FeatureFlagsModule.featureFlags), f => f.sequenceNumber);
  }
  load() {
    FeatureFlagsModule.getFeatureFlags();
  }
  created() {
    TeamsModule.getAllTeamsFromDb();
    this.load();
  }
}
